import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';

export default () => (
    <Card >
        <Title title="Developer Console" />
        <CardContent>
            <div>
                Hi {localStorage.getItem("name")},
            </div>
            <h1>
                Your Development Credentials
            </h1>
        </CardContent>
        <CardContent>
            <div>
                Region
            </div>
            <div>
                <textarea disabled style={{ width: '750px', height: '50px', fontSize: '20px' }}>{localStorage.getItem("region")}</textarea>
            </div>
            <div>
                <button onClick={() => { navigator.clipboard.writeText(localStorage.getItem("region")) }}>Copy Region</button>
            </div>
        </CardContent>
        <CardContent>
            <div>
                IdToken
            </div>
            <div>
                <textarea disabled style={{ width: '750px', height: '200px', fontSize: '20px' }}>{localStorage.getItem("token")}</textarea>
            </div>
            <div>
                <button onClick={() => { navigator.clipboard.writeText(localStorage.getItem("token")) }}>Copy IdToken</button>
            </div>
            <br></br>
            <div>
                NOTE: expiry time for the IdToken is 1 day and after that you are required to refresh it when expired by letting the user login again.
            </div>
        </CardContent>
        <CardContent>
            <div>
                UserPoolId
            </div>
            <div>
                <textarea disabled style={{ width: '750px', height: '50px', fontSize: '20px' }}>{localStorage.getItem("userPoolId")}</textarea>
            </div>
            <div>
                <button onClick={() => { navigator.clipboard.writeText(localStorage.getItem("userPoolId")) }}>Copy UserPoolId</button>
            </div>
        </CardContent>
        <CardContent>
            <div>
                AppClientId
            </div>
            <div>
                <textarea disabled style={{ width: '750px', height: '50px', fontSize: '20px' }}>{localStorage.getItem("sandboxAppClientId")}</textarea>
            </div>
            <div>
                <button onClick={() => { navigator.clipboard.writeText(localStorage.getItem("sandboxAppClientId")) }}>Copy AppClientId</button>
            </div>
        </CardContent>
        <CardContent>
            <div>
                AuthType
            </div>
            <div>
                <textarea disabled style={{ width: '750px', height: '50px', fontSize: '20px' }}>developer</textarea>
            </div>
            <div>
                <button onClick={() => { navigator.clipboard.writeText("developer") }}>Copy AuthType</button>
            </div>
        </CardContent>
        <CardContent>
            <div>
                AuthSub
            </div>
            <div>
                <textarea disabled style={{ width: '750px', height: '50px', fontSize: '20px' }}>{localStorage.getItem("sub")}</textarea>
            </div>
            <div>
                <button onClick={() => { navigator.clipboard.writeText(localStorage.getItem("sub")) }}>Copy AuthSub</button>
            </div>
        </CardContent>
    </Card>
);