import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {
    Title
} from 'react-admin';

export const Documentation = (props) => (
    <Card>
        <Title title="Development Documentations" />
        <CardContent>
            <h1>Development Documentations</h1>
        </CardContent>
        <hr />
        <CardContent>
            <h4>Useful Links</h4>
            <ul>
                <li>API documentations: </li>
                <ul>
                    <li><a style={{ color: 'red' }} href='https://app.swaggerhub.com/apis/1Receipt/1receipt-pos-integration/1.0.0' target='_blank'>1receipt POS API</a></li>
                    <li><a style={{ color: 'red' }} href='https://docs.amplify.aws/start/q/integration/js/' target='_blank'>Guide to setup AWS Amplify</a></li>
                    <li><a style={{ color: 'red' }} href='https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js/#sign-in' target='_blank'>Using the sign-in function to retrieve the token</a></li>
                </ul>
                <li><a style={{ color: 'red' }} href='https://jwt.io' target='_blank'>JWT debugger</a></li>
                <li><a style={{ color: 'red' }} href='http://pos.1receipt.io' target='_blank'>PDF version of the documentation</a></li>
                <li><a style={{ color: 'red' }} href='https://www.figma.com/file/ftAIAo3Ckz3ICqIbgzP1vJ/1receipt-POS-Integration-UI-Ref?node-id=77%3A2' target='_blank'>POS UI references in Figma</a></li>
                <li><a style={{ color: 'red' }} href='https://1drv.ms/f/s!AkE1SGco0ZlJj54HySN_1imGfGsKQA' target='_blank'>Media drive</a></li>
            </ul>
        </CardContent>
        <hr />
        <CardContent>
            <h4>1receipt Integration Flowchart</h4>
            <img src="https://1receipt-images.s3.ap-southeast-2.amazonaws.com/dev-portal/Integration-Phase-1.png" alt="1receipt Process Flowchart Phase 1" width="900" />
            <br />
            <br />
            <img src="https://1receipt-images.s3.ap-southeast-2.amazonaws.com/dev-portal/Integration-Phase-2.png" alt="1receipt Process Flowchart Phase 2" width="900" />
        </CardContent>
        <hr />
        <CardContent>
            <h4>Workflow Steps</h4>
            <ol>
                <li>Create a UI for the Retailer Login Dialogue</li>
                <br />
                <li>Create a UI for the Generic Receipt Dialogue Operation (1receipt, Paper Receipt, No Receipt)</li>
                <br />
                <li>Create a UI for the 1receipt Shopper Barcode Dialogue</li>
                <br />
                <li>Authenticate via AWS Cognito - Important step: before accessing the endpoints, it is to verify the IdToken.</li>
                    <ul>
                        <li>Alongside the IdToken to access our endpoint you need AuthType and AuthSub.</li>
                        <li>Accepted AuthType: [Developer, Retailer, Manager]</li>
                        <li>AuthSub is the sub of the Cognito user found in the payload. You need it for Developer or Manager AuthType</li>
                        <li>The retailer will login using their username and password. The username is their ABN when they register via retailer.1receipt.io.</li>
                    </ul>
                <br />
                <li>GET api/checkShopperId</li>
                    <ul>
                        <li>When the shopper successfully scans their card, you then have to call checkShopperId GET endpoint to make sure the shopper account is verified.</li>
                    </ul>
                <br />
                <li>POST api/receipt</li>
                    <ul>
                        <li>Create a receipt with the receipt model provided using the captured receipt.</li>
                    </ul>
            </ol>
        </CardContent>
        <hr />
        <CardContent>
            <h4>Sample 1receipt IdToken Payload</h4>
            <img src="https://1receipt-images.s3.ap-southeast-2.amazonaws.com/dev-portal/sample-idToken-payload.png" alt="Sample IdToken Payload" width="900"></img>
        </CardContent>
        <CardContent>
            <h4>Sample Output for GET api/checkShopperId</h4>
            <img src="https://1receipt-images.s3.ap-southeast-2.amazonaws.com/dev-portal/get-checkShopperId-output.png" alt="Sample IdToken Payload" width="900"></img>
        </CardContent>
        <CardContent>
            <h4>Sample Body Request for POST api/receipt</h4>
            <img src="https://1receipt-images.s3.ap-southeast-2.amazonaws.com/dev-portal/post-receipt-body.png" alt="Sample IdToken Payload" width="900"></img>
        </CardContent>
        <CardContent>
            <h4>Sample Response for POST api/receipt</h4>
            <img src="https://1receipt-images.s3.ap-southeast-2.amazonaws.com/dev-portal/post-receipt-response.png" alt="Sample IdToken Payload" width="900"></img>
        </CardContent>
    </Card>
);