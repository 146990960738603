export default {
    getList: async(resource, params) => Promise.reject,
    getOne: async(resource, params) => Promise.reject,
    getMany: (resource, params) => Promise.reject,
    getManyReference: (resource, params) => Promise.reject,
    create: async(resource, params) => Promise.reject,
    update: async(resource, params) => Promise.reject,
    updateMany: (resource, params) => Promise.reject,
    delete: async(resource, params) => Promise.reject,
    deleteMany: (resource, params) => Promise.reject,
};