import { Auth } from 'aws-amplify';

const axios = require('axios');
const jwkToPem = require('jwk-to-pem');
const jwt = require('jsonwebtoken');

async function verifyToken(token) {
    const CLIENT_ID = '7phkfadlfo1ctksa95pek6fh53';
    // set iss
    let ISSUER_URI =
        'https://cognito-idp.ap-southeast-2.amazonaws.com/ap-southeast-2_Bn6dUeH4A';

    const JWKS_URI = `${ISSUER_URI}/.well-known/jwks.json`;

    // get jwks as variable
    const response = await axios.get(JWKS_URI);
    const jwks = response.data.keys;
    console.log('jwks successfully loaded.');

    // check if there are three parts
    const tokenSections = (token || '').split('.');
    if (tokenSections.length < 2) {
        throw new Error('requested token is invalid');
    }

    // decode header
    const headerJSON = Buffer.from(tokenSections[0], 'base64').toString('utf8');
    const header = JSON.parse(headerJSON);
    console.log('header decoded.');

    // get publickey using header from jwks
    let jwk;
    for (let i = 0; i < jwks.length; i++) {
        const currentKey = jwks[i];
        if (currentKey.kid === header.kid) {
            jwk = currentKey;
            console.log('jwk found.');
            break;
        }
    }

    // convert a json web key to a PEM
    const pem = jwkToPem(jwk);

    // verify token
    try {
        const decoded = jwt.verify(token, pem, {
            algorithms: [header.alg],
            audience: CLIENT_ID,
            issuer: ISSUER_URI,
        });
        return true;
    } catch (err) {
        console.error('Token invalid:', err);
        throw err;
    }
}

export default {
    login: async ({ username, password }) => {
        try {
            // Verify with cognito
            const user = await Auth.signIn(username, password);
            console.log(user);
            if (user.challengeName) {
                return Promise.reject(user.challengeName);
            }
            localStorage.setItem('token', user.signInUserSession.idToken.jwtToken);
            localStorage.setItem('sub', user.attributes.sub);

            // Get developer record
            const option = {
                method: 'GET',
                headers: { Authorization: localStorage.getItem('token') },
                url: 'https://nodejs-api-stage.1receipt.io/api/developer?id=' + user.attributes.sub + '&authType=developer',
            };
            try {
                let res = await axios(option);
                if (res.data) {
                    console.log(`getOneResData: ${JSON.stringify(res.data, null, 2)}`);
                    // Save record in localStorage
                    localStorage.setItem('name', res.data.Name);
                    localStorage.setItem('email', res.data.Email);
                    localStorage.setItem('sandboxAppClientId', res.data.AppClientId);
                    localStorage.setItem('region', res.data.Region);
                    localStorage.setItem('userPoolId', res.data.UserPoolId);
                    return Promise.resolve();
                } else {
                    return Promise.reject(new Error('No record'));
                }
            } catch (err) {
                console.error(err);
                return Promise.reject(err);
            }
        } catch (err) {
            return Promise.reject(err);
        }
    },
    logout: () => {
        localStorage.removeItem('token');
        return Promise.resolve();
    },
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: async () => {
        let token = localStorage.getItem('token');
        if (token) {
            try {
                await verifyToken(token);
                return Promise.resolve();
            } catch (err) {
                return Promise.reject(err);
            }
        } else {
            return Promise.reject();
        }
    },
    getPermissions: () => Promise.resolve(),
};