import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {
    Title
} from 'react-admin';

export const Support = (props) => (
    <Card>
        <Title title="Contact Info"/>
        <CardContent>
            <h1>Contact Info</h1>
        </CardContent>
        <hr />
        <CardContent>
            <h4>If you have any queries regarding the integration feel free to send us an email and a member of our team will get back to you.</h4>
            <ul>
                <li><a style={{ color: 'red' }} href='https://forms.gle/oGsNs9W12e6hxXjw7' target='_blank'>Support Form</a></li>
                <li><a style={{ color: 'red' }} href='mailto:dev@1receipt.io?subject=Query regarding POS integration' target='_blank'>dev@1receipt.io</a></li>
                <li><a style={{ color: 'red' }} href='' target='_blank'>03 9028 8298</a></li>
            </ul>
        </CardContent>
    </Card>
);