import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { Admin, Resource, Layout } from 'react-admin';
import { Documentation } from './components/documentation';
import { Support } from './components/support';
import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';
import Dashboard from './Dashboard';
import DevelopmentIcon from '@material-ui/icons/DeveloperMode';
import SupportIcon from '@material-ui/icons/Help';
import TreeMenu from '@bb-tech/ra-treemenu';

const theme = createMuiTheme({
	palette: {
		type: 'dark',
	},
});

function App() {
	return (
		<Admin
			theme={theme}
			dashboard={Dashboard}
			title="Developer Console"
			authProvider={authProvider}
			dataProvider={dataProvider}
			layout={(props) => <Layout {...props} menu={TreeMenu} />}
		>
			<Resource name="Documentation" options={{ "label": "Documentation" }} list={Documentation} icon={DevelopmentIcon} />
			<Resource name="Support" options={{ "label": "Support" }} list={Support} icon={SupportIcon} />
		</Admin>
	);
}

export default App;
